import { create } from 'zustand';

import type { Product } from '../../types';

interface IAppStorState {
  step: number;
  filter: {
    search: string;
    manufacturer: string;
    type: string;
    color: string;
  };
  batteriesAlertSeen?: boolean;
  resetAppState: () => void;
  setFilter: (propertyKey: string, value: string) => void;
  selectedProduct?: Product;
  setSelectedProduct: (product: Product | undefined) => void;
  increase: (by: number) => void;
  decrease: (by: number) => void;
  setStep: (step: number) => void;
  setBatteriesAlertSeen: (seen: boolean) => void;
}

const initialState = {
  step: 1,
  filter: {
    search: '',
    manufacturer: '',
    type: '',
    color: ''
  },
  selectedProduct: undefined
};

const useAppStore = create<IAppStorState>(set => ({
  ...initialState,
  resetAppState: () => {
    set(initialState);
  },
  setFilter: (propertyKey: string, value: string) => set(state => {
    if (
      propertyKey === 'manufacturer'
      && value === ''
    ) {
      return {
        filter: {
          ...state.filter,
          [propertyKey]: value,
          type: '',
          color: ''
        }
      };
    }

    return {
      filter: {
        ...state.filter,
        [propertyKey]: value
      }
    };
  }),
  setBatteriesAlertSeen: (seen: boolean) => set({ batteriesAlertSeen: seen }),
  setSelectedProduct: (product: Product | undefined) => set({ selectedProduct: product }),
  increase: (by: number) => set(state => ({ step: state.step + by })),
  decrease: (by: number) => set(state => ({ step: state.step - by })),
  setStep: (step: number) => set(state => {
    /*
    {step === 1 && (
      <StepServiceSelect />
    )}
    {step === 2 && (
      <StepProductSelect />
    )}
    {step === 3 && (
      <StepProductInfo />
    )}
    {step === 4 && (
      <StepAccessoriesSelect />
    )}
    {step === 5 && (
      <StepCustomerData onClick={placeOrder} />
    )}
    {step === 6 && (
      <StepConfirmation />
    )}
    */
    let stepToSet;

    if (
      // No product selected -> jump to product select
      step === 3
      && !state.selectedProduct
    ) {
      stepToSet = 2;
    } else {
      stepToSet = step;
    }

    return {
      step: stepToSet
    };
  })
}));

export default useAppStore;
