import axios from 'axios';
import classNames from 'classnames';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import React from 'react';

import Cart from '../../Cart';
import Headlines from '../../components/Headlines';
import ConfigContext from '../../service/context/config';
import useAppStore from '../../service/store/app';
import useOrderStore from '../../service/store/order';
import StepAccessoriesSelect from '../../steps/StepAccessoriesSelect';
import StepConfirmation from '../../steps/StepConfirmation';
import StepCustomerData from '../../steps/StepCustomerData';
import StepProductInfo from '../../steps/StepProductInfo';
import StepProductSelect from '../../steps/StepProductSelect';
import StepServiceSelect from '../../steps/StepServiceSelect';
import type { AppConfig, OrderApiResponse } from '../../types';
import { scrollToRef } from '../../utils/scroll';
import { getServiceProvider } from '../../utils/service_provider';

import { MAX_STEPS_SERVICE } from './constants';

const ServiceForm: FC = () => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ config, setConfig ] = useState<AppConfig | null>(null);
  const [ orderLoading, setOrderLoading ] = useState<boolean>(false);
  const [ orderSuccess, setOrderSuccess ] = useState<boolean>(false);

  const { order, resetOrderState } = useOrderStore(state => state) || {};
  const { step, resetAppState, setStep } = useAppStore(state => state);
  const { key: serviceProviderKey, name: serviceProviderName } = getServiceProvider();

  const resetApp = (): void => {
    if (
      step === MAX_STEPS_SERVICE
      && orderSuccess
      && !orderLoading
    ) {
      resetOrderState();
      resetAppState();
      setOrderSuccess(false);
      scrollToRef();
    }
  };

  const placeOrder = async (): Promise<void> => {
    if (!orderLoading) {
      try {
        setOrderLoading(true);
        setStep(6);
        const { data } = await axios.post<OrderApiResponse>(`${process.env.REACT_APP_API_URL}/order/`, {
          nonce: config?.nonce,
          serviceProviderKey,
          serviceProviderName,
          order
        });

        if (data) {
          // eslint-disable-next-line no-console
          // console.log(data);
          setOrderLoading(false);
          if (data.ok) {
            setOrderSuccess(true);
          }
        }
      } catch (error) {
        // if (axios.isAxiosError(error)) {
        //   // eslint-disable-next-line no-console
        //   console.log('error message: ', error.message);
        // } else {
        //   // eslint-disable-next-line no-console
        //   console.log('unexpected error: ', error);
        // }
        setOrderLoading(false);
      }
    }
  };

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const { data } = await axios.get<AppConfig>(
        `${process.env.REACT_APP_API_URL}/config/`
      );

      setConfig(data);

      setLoading(false);
    };

    void getData();
  }, []);

  if (loading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <ConfigContext.Provider value={config}>
      <div className="app">
        <div className="container">
          <Headlines
            reset={resetApp}
            setStep={setStep}
            step={step}
          />
        </div>
        <div className={classNames('container', {
          'has-content-navi': step > 1 && step < 6
        })}>
          <div className="left">
            {step === 1 && (
              <StepServiceSelect />
            )}
            {step === 2 && (
              <StepProductSelect />
            )}
            {step === 3 && (
              <StepProductInfo />
            )}
            {step === 4 && (
              <StepAccessoriesSelect />
            )}
            {step === 5 && (
              <StepCustomerData onClick={placeOrder} />
            )}
            {step === 6 && (
              <StepConfirmation reset={resetApp} />
            )}
          </div>
          <div className="right">
            <div>
              <Cart />
              <div id="cart-portal"></div>
            </div>
          </div>
        </div>
      </div>
    </ConfigContext.Provider>
  );
};

export default ServiceForm;
