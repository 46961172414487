import type { FC } from 'react';
import React from 'react';

import ServiceCard from '../components/ServiceCard';
import '../components/ServiceCard.scss';
import useAppStore from '../service/store/app';
import useOrderStore from '../service/store/order';
import { scrollToRef } from '../utils/scroll';

import './StepServiceSelect.scss';

const StepServiceSelect: FC = () => {
  const setOrderValue = useOrderStore(state => state.setValue);
  const increase = useAppStore(state => state.increase);

  const handleClick = (value: string): void => {
    setOrderValue(value, 'service');
    increase(1);
    scrollToRef();
  };

  return (
    <div>
      <div className="service-card-warp">
        <ServiceCard
          hint="Dein Gerät funktioniert noch"
          image="inspektion.jpg"
          imageIcon="inspektion.svg"
          type="Inspektion"
          title="Inspektion/Erinnerungsservice"
          description="Lass’ dein Pressgerät abholen, im Koffer oder im Karton."
          onClick={handleClick}
        />
        <ServiceCard
          hint="Dein Gerät ist kaputt"
          image="reparatur.jpg"
          imageIcon="reparatur.svg"
          type="Reparatur"
          title="Reparatur"
          description="Lass’ dein Pressgerät abholen, im Koffer oder im Karton."
          onClick={handleClick}
        />
      </div>
      <div className="service-cards-info">
        Du willst ein Gerät mieten oder einen Vor-Ort-Service Beauftragen?<br />
        <a href="https://www.toolservice.de/">Zur Service-Übersicht</a>
      </div>
      <div className="service-cards-legal">
        Hinweis: Dieses Formular wird von www.toolservice.de betrieben. Geräte von einigen Herstellern
        werden von unseren Partnerbetrieben bearbeitet. Mit der Benutzung dieses Formulars stimmen
        Sie unseren <a href="#">AGBs</a> zu.
      </div>
    </div>
  );
};

export default StepServiceSelect;
