import { uniq } from 'lodash';
import { create } from 'zustand';

import type { IRentalProduct } from '../../types';

interface IAppStoreStateRent {
  step: number;
  products: IRentalProduct[];
  filteredProducts: IRentalProduct[];
  filter: {
    system: string;
    manufacturer: string;
    search: string;
    type: string;
    material: string;
  };
  availableFilter: {
    system: string[];
    type: string[];
    material: string[];
    manufacturer: string[];
  };
  resetAppState: () => void;
  setProducts: (products: IRentalProduct[]) => void;
  setFilteredProducts: (products: IRentalProduct[]) => void;
  setAvailableFilter: (availableFilter: IAppStoreStateRent['availableFilter']) => void;
  setFilter: (propertyKey: string, value: string) => void;
  setFilterAdvanced: (propertyKey: string, value: string) => void;
  increase: (by: number) => void;
  decrease: (by: number) => void;
  setStep: (step: number) => void;
}

const initialState = {
  step: 1,
  products: [],
  filteredProducts: [],
  filter: {
    system: '',
    manufacturer: '',
    search: '',
    type: '',
    material: ''
  },
  availableFilter: {
    system: [],
    type: [],
    material: [],
    manufacturer: []
  },
  selectedProduct: undefined
};

const useAppStoreRent = create<IAppStoreStateRent>(set => ({
  ...initialState,
  resetAppState: () => {
    set(initialState);
  },
  setProducts: (products: IRentalProduct[]) => set({ products }),
  setFilteredProducts: (filteredProducts: IRentalProduct[]) => set({ filteredProducts }),
  setFilter: (propertyKey: string, value: string) => set(state => ({
    filter: {
      ...state.filter,
      [propertyKey]: value
    }
  })),
  setFilterAdvanced: (propertyKey: string, value: string) => {
    set(state => {
      const filter = {
        ...state.filter,
        [propertyKey]: value
      };

      const {
        search,
        system,
        type,
        material,
        manufacturer
      } = filter;

      const filteredProducts = state.products?.filter(productItem => {
        if (
          !!search
          && (
            !productItem.name.toLowerCase().includes(search.toLowerCase())
            && !productItem.id.includes(search)
          )
        ) {
          return false;
        }

        if (!!system && !productItem.system.includes(system)) {
          return false;
        }

        if (!!type && productItem.type !== type) {
          return false;
        }

        if (!!material && !productItem.material.includes(material)) {
          return false;
        }

        if (!!manufacturer && !productItem.manufacturer.includes(manufacturer)) {
          return false;
        }

        return true;
      });

      const tmpAvailableFilter: {
        system: string[];
        type: string[];
        material: string[];
        manufacturer: string[];
      } = {
        system: [],
        type: [],
        material: [],
        manufacturer: []
      };

      filteredProducts?.forEach(productItem => {
        tmpAvailableFilter.manufacturer.push(
          ...productItem.manufacturer
        );

        tmpAvailableFilter.system.push(
          ...productItem.system
        );

        tmpAvailableFilter.type.push(productItem.type);

        tmpAvailableFilter.material.push(
          ...productItem.material
        );
      });

      tmpAvailableFilter.manufacturer = uniq(tmpAvailableFilter.manufacturer).sort();
      tmpAvailableFilter.system = uniq(tmpAvailableFilter.system).sort();
      tmpAvailableFilter.type = uniq(tmpAvailableFilter.type).sort();
      tmpAvailableFilter.material = uniq(tmpAvailableFilter.material).sort();

      return {
        filter,
        availableFilter: tmpAvailableFilter,
        filteredProducts
      };
    });
  },
  setAvailableFilter: (availableFilter: IAppStoreStateRent['availableFilter']) => set({ availableFilter }),
  increase: (by: number) => set(state => ({ step: state.step + by })),
  decrease: (by: number) => set(state => ({ step: state.step - by })),
  setStep: (step: number) => set(() => {
    let stepToSet;

    // if (
    //   // No product selected -> jump to product select
    //   step === 3
    //   && !state.selectedProduct
    // ) {
    //   stepToSet = 2;
    // } else {
    //   stepToSet = step;
    // }

    // eslint-disable-next-line prefer-const
    stepToSet = step;

    return {
      step: stepToSet
    };
  })
}));

export default useAppStoreRent;
