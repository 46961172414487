import { produce } from 'immer';
import { omit, set as lSet } from 'lodash';
import { create } from 'zustand';

import { OPTION_PICKUP } from '../../forms/rent/constants';
import type { IDeliveryOption, IPickupOption, IRentalProduct, OrderRent } from '../../types';
import { findNextWeekday } from '../../utils/date';

export type TRentalProductCart = Omit<IRentalProduct, 'system' | 'manufacturer' | 'material'>;

interface IOrderStateRent {
  nonce?: string;
  order: OrderRent;
  addProduct: (product: IRentalProduct, quantity?: number) => void;
  changeProductQuantity: (productId: number, quantity: number) => void;
  removeProduct: (productId: number) => void;
  resetOrderState: (data?: { [key: string]: IDeliveryOption | IPickupOption }) => void;
  setNonce: (nonce: string) => void;
  setValue: (value: string | boolean | number | IDeliveryOption | IPickupOption, key: string) => void;
}

const initialState = {
  order: {
    // // extra setter
    products: {},
    delivery_type: OPTION_PICKUP,
    checkedLegal: false,
    // generic setter
    customer_number: '',
    reference_number: '',
    delivery_date: findNextWeekday(new Date())?.toLocaleDateString('de-DE'),
    pickup_date: findNextWeekday(new Date())?.toLocaleDateString('de-DE'),
    delivery_option: undefined,
    pickup_option: undefined,
    // pickup_date: today.toLocaleDateString('de-DE'),
    // address setter
    deliveryAddress: undefined,
    diffDeliveryAddress: false,
    invoiceAddress: undefined,
    diffInvoiceAddress: false,
    address: undefined
  }
};

const useOrderStoreRent = create<IOrderStateRent>(set => ({
  ...initialState,
  resetOrderState: data => {
    set({
      ...initialState,
      order: {
        ...initialState.order,
        ...data
      }
    });
  },
  addProduct: (product: IRentalProduct, quantity = 1) =>
    set(state =>
      produce(state, draft => {
        const existingItem = draft.order?.products[product.id];

        if (existingItem) {
          existingItem.quantity = (existingItem.quantity ?? 0) + quantity;
        } else {
          draft.order.products[product.id] = {
            ...omit(product, [ 'system', 'manufacturer', 'material' ]),
            quantity
          };
        }
      })
    ),
  changeProductQuantity: (productId: number, quantity: number) =>
    set(state =>
      produce(state, draft => {
        const existingItem = draft.order.products[productId];

        if (existingItem) {
          existingItem.quantity = quantity;
        }
      })
    ),
  removeProduct: (productId: number) =>
    set(state =>
      produce(state, draft => {
        if (draft.order.products[productId]) {
          delete draft.order.products[productId];
        }
      })
    ),
  // setters
  setNonce: nonce =>
    set(
      produce(draft => {
        draft.nonce = nonce;
      })
    ),
  setValue: (value, key) =>
    set(
      produce(draft => {
        lSet(draft.order, key, value);
      })
    )
}));

export default useOrderStoreRent;
