import classNames from 'classnames';
import { capitalize, map, sortBy, uniqBy } from 'lodash';
import type { FC } from 'react';
import React, { useContext } from 'react';

import { useManufacturer } from '../lib/manufacturer';
import ConfigContext from '../service/context/config';
import useAppStore from '../service/store/app';
import { UNKNOWN } from '../utils/constants';

import './Filter.scss';
import Icon from './ui/Icon';

const Filter: FC = () => {
  const appContext = useContext(ConfigContext);
  const { products, manufacturers, hero_manufacturers } = appContext || {};
  const colorOptions = sortBy(uniqBy(products, 'color'), 'color');
  const typeOptions = sortBy(uniqBy(products, 'type_of_drive'));
  // const filter = useAppStore(state => state.filter);
  const { search, manufacturer, type, color } = useAppStore(state => state.filter);
  const setFilter = useAppStore(state => state.setFilter);
  const {
    manufacturer: brands,
    topManufacturer: heroBrands
  } = useManufacturer(manufacturers, hero_manufacturers);

  return (
    <div className="filter-wrap">
      <div className="filter-item">
        <label>Marke/Hersteller</label>
        <select
          onChange={event => setFilter('manufacturer', event.target.value)}
        >
          <option value="">bitte wählen</option>
          {heroBrands.length > 0 && (
            <>
              {map(heroBrands, manufacturerOption => (
                <option
                  key={manufacturerOption.key}
                  value={manufacturerOption.name}
                  selected={manufacturerOption.name === manufacturer}
                >
                  {manufacturerOption.name}
                </option>
              ))}
              <option value="">------</option>
            </>
          )}
          {map(brands, manufacturerOption => (
            <option
              key={manufacturerOption.key}
              value={manufacturerOption.name}
              selected={manufacturerOption.name === manufacturer}
            >
              {manufacturerOption.name}
            </option>
          ))}
          <option value={UNKNOWN}>{capitalize(UNKNOWN)}</option>
        </select>
      </div>
      <div className="filter-item">
        <label>Gerätebezeichnung</label>
        <div className={classNames('filter-item-serach', {
          disabled: !manufacturer
        })}>
          <Icon icon="search" />
          <input
            onChange={event => setFilter('search', event.target.value)}
            type="text"
            value={search}
            placeholder="Suche"
            disabled={!manufacturer}
          />
        </div>
      </div>
      <div className={classNames('filter-item', {
        'filter-item-disabled': !manufacturer
      })}>
        <label>Antriebsart</label>
        <select
          onChange={event => setFilter('type', event.target.value)}
          disabled={!manufacturer}
        >
          {map(typeOptions, typeOption => (
            <option
              key={typeOption.id}
              value={typeOption.type_of_drive}
              selected={typeOption.type_of_drive === type}
            >
              {typeOption.type_of_drive}
            </option>
          ))}
        </select>
      </div>
      <div className={classNames('filter-item', {
        'filter-item-disabled': !manufacturer
      })}>
        <label>Farbe</label>
        <select
          onChange={event => setFilter('color', event.target.value)}
          disabled={!manufacturer}
        >
          {map(colorOptions, colorOption => (
            <option
              key={colorOption.id}
              value={colorOption.color}
              selected={colorOption.color === color}
            >
              {colorOption.color}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filter;
