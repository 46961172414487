import { map, size, times, toNumber } from 'lodash';
import type { FC } from 'react';
import { useContext } from 'react';
import React from 'react';

import Button from '../../../components/ui/Button';
import Icon from '../../../components/ui/Icon';
import Image from '../../../components/ui/Image';
import ConfigRentContext from '../../../service/context/config-rent';
import type { TRentalProductCart } from '../../../service/store/orderRent';
import useOrderStoreRent from '../../../service/store/orderRent';

import { formatCurrency } from './../../../utils/format';
import './CartProducts.scss';

const CartProducts: FC = () => {
  const appContext = useContext(ConfigRentContext);
  const { base_rent = 0 } = appContext || {};
  const {
    products
  } = useOrderStoreRent(state => state.order) || {};
  const removeProduct = useOrderStoreRent(state => state.removeProduct);
  const setProductQuantity = useOrderStoreRent(state => state.changeProductQuantity);

  const handleRemoveProduct = (product: TRentalProductCart): void => {
    removeProduct(toNumber(product.id));
  };

  const handleProductQuantityChange = (product: TRentalProductCart, quantity: number): void => {
    setProductQuantity(toNumber(product.id), quantity);
  };

  return (
    <div>
      <div className="cart-head-normal">Mietübersicht</div>
      {base_rent > 0 && (
        <div className="cart-row">
          <div className="cart-row-inner">
            <div className="name">Mietauftragspauschale:</div>
            <div className="currency">{formatCurrency(base_rent)}</div>
          </div>
        </div>
      )}
      <div className="cart-row">
        <div className="cart-row-inner cart-row-inner-head cart-row-inner-rent">
          <div className="amount">Stk.</div>
          <div className="name">Geräte</div>
          <div className="currency">Mietepreis pro Tag</div>
        </div>
      </div>
      {size(products) === 0 ? (
        <div className="cart-row">
          <em>Noch keine Produkte gewählt.</em>
        </div>
      ) : (
        <div className="cart-row">
          {map(products, product => {
            const {
              image,
              name,
              price,
              quantity
            } = product;

            return (
              <div className="card-prodct-row">
                <div className="card-prodct-row-actions">
                  <div className="styled-inputx">
                    <select
                      value={quantity}
                      onChange={e => handleProductQuantityChange(product, parseInt(e.target.value, 10))}
                    >
                      {map(times(98), i => {
                        const currentVal = i + 1;

                        return (
                          <option key={currentVal} value={currentVal}>
                            {currentVal}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="card-prodct-row-info-warp">
                  <div className="card-prodct-row-info">
                    <div className="image">
                      {image ? (
                        <Image
                          url={image}
                          alt={name}
                          title={name}
                          type="rent"
                          className="product-item-image"
                        />
                      ) : (
                        <div className="product-image unknown">
                          <Icon icon="question" />
                        </div>
                      )}
                    </div>
                    <div className="name">
                      {name}
                    </div>
                  </div>
                </div>
                <div className="card-prodct-row-price">
                  {formatCurrency(toNumber(price))}
                </div>
                <div className="card-prodct-row-actions">
                  <Button
                    className="delete-btn"
                    onClick={() => handleRemoveProduct(product)}
                    resetBtn
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CartProducts;
